import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import "./animations.css"; // Import the CSS file
import { Box, Card, Typography, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MatchCard from "../Matches/matchcard";
import axios from "../../authAxios";
import MainFooter from "../MainFooter/MainFooter";

const topGames = [
  { name: "AVIATOR", bgImg: "/image/Topgames/AVIATOR.png" },
  { name: "JETX3", bgImg: "/image/Topgames/JETX3.png" },

  {
    name: "TEEN PATTI",
    bgImg: "/image/Topgames/TEENPATTI.png",
  },
  {
    name: "HEADS & TAILS",
    bgImg: "/image/Topgames/HEADS&TAILS.png",
  },
  {
    name: "2 CARDS TEENPATTI",
    bgImg: "/image/Topgames/2CARDSTEENPATTI.png",
  },
  { name: "ONE DAY", bgImg: "/image/Topgames/ONEDAY.png" },
  {
    name: "BOLLYWOOD CASINO",
    bgImg: "/image/Topgames/BOLLYWOODCASINO.png",
  },
  {
    name: "WORLI MARKA",
    bgImg: "/image/Topgames/WORLIMARKA.png",
  },
  {
    name: "DRAGON TIGER",
    bgImg: "/image/Topgames/DRAGONTIGER.png",
  },

  { name: "JETX", bgImg: "/image/Topgames/JETX.png" },
  {
    name: "ANDAR BAHAR",
    bgImg: "/image/Topgames/ANDARBAHAR.png",
  },
  {
    name: "DRAGON TIGER LION",
    bgImg: "/image/Topgames/DRAGONTIGERLION.png",
  },
  {
    name: "PORTOMASO REAL",
    bgImg: "/image/Topgames/PORTOMASOREAL.png",
  },

  {
    name: "ROULETTE",
    bgImg: "/image/Topgames/ROULETTE.png",
  },
  { name: "TRIO", bgImg: "/image/Topgames/TRIO.png" },
  { name: "LOTTERY", bgImg: "/image/Topgames/LOTTERY.png" },
  {
    name: "BOOK CRICKET",
    bgImg: "/image/Topgames/BOOKCRICKET.png",
  },

  {
    name: "2 CARD TEEN PATTI ONE DAY",
    bgImg: "/image/Topgames/2CARDTEENPATTIONEDAY.png",
  },
];

const gameShows = [
  { name: "AVIATOR", bgImg: "/image/Topgames/AVIATOR.png" },
  { name: "JETX3", bgImg: "/image/Topgames/JETX3.png" },

  {
    name: "TEEN PATTI",
    bgImg: "/image/Topgames/TEENPATTI.png",
  },
  {
    name: "HEADS & TAILS",
    bgImg: "/image/Topgames/HEADS&TAILS.png",
  },
  {
    name: "2 CARDS TEENPATTI",
    bgImg: "/image/Topgames/2CARDSTEENPATTI.png",
  },
  { name: "ONE DAY", bgImg: "/image/Topgames/ONEDAY.png" },
  {
    name: "BOLLYWOOD CASINO",
    bgImg: "/image/Topgames/BOLLYWOODCASINO.png",
  },
  {
    name: "WORLI MARKA",
    bgImg: "/image/Topgames/WORLIMARKA.png",
  },
  {
    name: "DRAGON TIGER",
    bgImg: "/image/Topgames/DRAGONTIGER.png",
  },

  { name: "JETX", bgImg: "/image/Topgames/JETX.png" },
  {
    name: "ANDAR BAHAR",
    bgImg: "/image/Topgames/ANDARBAHAR.png",
  },
  {
    name: "DRAGON TIGER LION",
    bgImg: "/image/Topgames/DRAGONTIGERLION.png",
  },
  {
    name: "PORTOMASO REAL",
    bgImg: "/image/Topgames/PORTOMASOREAL.png",
  },

  {
    name: "ROULETTE",
    bgImg: "/image/Topgames/ROULETTE.png",
  },
  { name: "TRIO", bgImg: "/image/Topgames/TRIO.png" },
  { name: "LOTTERY", bgImg: "/image/Topgames/LOTTERY.png" },
  {
    name: "BOOK CRICKET",
    bgImg: "/image/Topgames/BOOKCRICKET.png",
  },
  {
    name: "2 CARD TEEN PATTI ONE DAY",
    bgImg: "/image/Topgames/2CARDTEENPATTIONEDAY.png",
  },
];

const bonus = [
  { name: "BONUS1", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS2", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS3", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS4", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS5", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
];

const royalGames = [
  { name: "AVIATOR", link: "/aviator", bgImg: "/image/Topgames/AVIATOR.png" },
  { name: "JETX3", link: "/jetx3", bgImg: "/image/Topgames/JETX3.png" },
  {
    name: "RULETA DEL SOL",
    link: "/ruleta-del-sol",
    bgImg: "/image/Topgames/RULETADELSOL.png",
  },
  {
    name: "TEEN PATTI",
    link: "/teen-patti",
    bgImg: "/image/Topgames/TEENPATTI.png",
  },
  {
    name: "HEADS & TAILS",
    link: "/heads-tails",
    bgImg: "/image/Topgames/HEADS&TAILS.png",
  },
  {
    name: "2 CARDS TEENPATTI",
    link: "/2-cards-teenpatti",
    bgImg: "/image/Topgames/2CARDSTEENPATTI.png",
  },
  { name: "ONE DAY", link: "/one-day", bgImg: "/image/Topgames/ONEDAY.png" },
  {
    name: "BOLLYWOOD CASINO",
    link: "/bollywood-casino",
    bgImg: "/image/Topgames/BOLLYWOODCASINO.png",
  },
  {
    name: "WORLI MARKA",
    link: "/worli-marka",
    bgImg: "/image/Topgames/WORLIMARKA.png",
  },
  {
    name: "DRAGON TIGER",
    link: "/dragon-tiger",
    bgImg: "/image/Topgames/DRAGONTIGER.png",
  },
  {
    name: "UNLIMITED",
    link: "/unlimited",
    bgImg: "/image/Topgames/UNLIMITED.png",
  },
  { name: "JETX", link: "/jetx", bgImg: "/image/Topgames/JETX.png" },
  {
    name: "ANDAR BAHAR",
    link: "/andar-bahar",
    bgImg: "/image/Topgames/ANDARBAHAR.png",
  },
  {
    name: "DRAGON TIGER LION",
    link: "/dragon-tiger-lion",
    bgImg: "/image/Topgames/DRAGONTIGERLION.png",
  },
  {
    name: "PORTOMASO REAL",
    link: "/portomaso-real",
    bgImg: "/image/Topgames/PORTOMASOREAL.png",
  },
  {
    name: "PERSONAL",
    link: "/personal",
    bgImg: "/image/Topgames/PERSONAL.png",
  },
  {
    name: "ROULETTE",
    link: "/roulette",
    bgImg: "/image/Topgames/ROULETTE.png",
  },
  { name: "3 CARDS", link: "/3-cards", bgImg: "/image/Topgames/3 CARDS.png" },
  { name: "TRIO", link: "/trio", bgImg: "/image/Topgames/TRIO.png" },
  { name: "LOTTERY", link: "/lottery", bgImg: "/image/Topgames/LOTTERY.png" },
  {
    name: "BOOK CRICKET",
    link: "/book-cricket",
    bgImg: "/image/Topgames/BOOKCRICKET.png",
  },
  {
    name: "ANDAR BAHAR INDAIN GAME",
    link: "/andar-bahar-indian-game",
    bgImg: "/image/Topgames/ANDAR BAHARINDAINGAME.png",
  },
  {
    name: "2 CARD TEEN PATTI ONE DAY",
    link: "/2-card-teen-patti-one-day",
    bgImg: "/image/Topgames/2CARDTEENPATTIONEDAY.png",
  },
];

const TopGamesPlayOption = ({ game, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      onClick={() => onClick(game.link)}
      sx={{
        width: isMobile ? "100px" : "120px",
        height: isMobile ? "60px" : "80px",
        margin: "0px",
        backgroundImage: `url(${game.bgImg})`,
        backgroundColor: "gray",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "2px solid white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "5px",
        cursor: "pointer",
      }}
    >
      <Box sx={{ flexGrow: 1 }}></Box>{" "}
    </Card>
  );
};

const BonusPlayOption = ({ bonus, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      onClick={() => onClick(bonus.link)}
      sx={{
        width: isMobile ? "97%" : "100%",
        height: isMobile ? "160px" : "110px",
        marginLeft: "10px",
        backgroundImage: `url(${bonus.bgImg})`,
        backgroundColor: "gray",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "0px solid white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px",
        cursor: "pointer",
      }}
    >
      <Box sx={{ flexGrow: 1 }}></Box>{" "}
    </Card>
  );
};

const GameShowsPlayOption = ({ game, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      onClick={() => onClick(game.link)}
      sx={{
        width: isMobile ? "100px" : "120px",
        height: isMobile ? "60px" : "80px",
        margin: "0px",
        backgroundImage: `url(${game.bgImg})`,
        backgroundColor: "gray",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "2px solid white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "5px",
        cursor: "pointer",
      }}
    >
      <Box sx={{ flexGrow: 1 }}></Box>{" "}
    </Card>
  );
};

const InPlayOption = ({ matchCount, bgImg, game, onClick }) => {
  return (
    <div
      style={{
        width: "120px",
        height: "80px",
        margin: "8px",
        backgroundImage: `url(${bgImg})`,
        backgroundColor: "gray",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "2px solid white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "5px",
        color: "white",
        textShadow: "0 0 4px black",
        cursor: "pointer",
      }}
      onClick={() => onClick(game)}
    >
      <Typography variant="body2">{game}</Typography>
      <Typography variant="caption">{matchCount} Matches</Typography>
    </div>
  );
};

const GameOption = ({ matchCount, bgImg, game, logo, onClick }) => {
  const logos = {
    Cricket: "/image/games/cricket_logo.webp",
    Soccer: "/image/games/soccer_logo.webp",
    Tennis: "/image/games/tennis_logo.webp",
  };

  const backgroundImages = {
    Cricket: "url('/image/games/cricket_logo_bck.webp')",
    Soccer: "url('/image/games/soccer_logo_bck.webp')",
    Tennis: "url('/image/games/tennis_logo_bck.webp')",
  };

  const backgroundStyle = backgroundImages[game.name]
    ? `${
        backgroundImages[game.name]
      }, linear-gradient(92.6deg, rgba(37, 110, 116, 0.8) 1.44%, rgba(60, 164, 126, 0.8) 98.28%)`
    : `linear-gradient(92.6deg, rgba(37, 110, 116, 0.8) 1.44%, rgba(60, 164, 126, 0.8) 98.28%)`;

  return (
    <Box
      sx={{
        width: "auto",
        height: "auto",
        margin: "8px",
        backgroundImage: backgroundStyle,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "5px",
        color: "white",
        textShadow: "0 0 4px rgba(0, 0, 0, 0.8)",
        cursor: game.enabled ? "pointer" : "not-allowed",
        opacity: game.enabled ? 1 : 0.5,
        boxShadow: 3,
        transition: "background-color 0.3s ease",
      }}
      onClick={() => {
        if (game.name === "Cricket") {
          if (game.enabled) {
            onClick("/live/4");
          }
        }
        if (game.name === "Soccer") {
          if (game.enabled) {
            onClick("/live/1");
          }
        }
        if (game.name === "Tennis") {
          if (game.enabled) {
            onClick("/live/2");
          }
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "7px 9px",
          justifyContent: "center",
        }}
      >
        {(game.name === "Cricket" ||
          game.name === "Soccer" ||
          game.name === "Tennis") && (
          <Box
            component="img"
            src={logos[game.name]}
            alt={`${game.name} logo`}
            sx={{ width: "20px", height: "20px", marginRight: "4px" }}
          />
        )}

        <Typography variant="body2">{game.name}</Typography>
      </Box>
    </Box>
  );
};

const MenuItem = ({ label, bgImg, path }) => {
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate(path);
  };

  return (
    <div
      style={{
        minWidth: "130px",
        border: "2px solid white",
        borderRadius: "7px",
        padding: "10px",
        textAlign: "right",
        marginRight: "10px",
        background: "linear-gradient(#193FB0,#2157E9)",
      }}
      onClick={handleMenuClick}
    >
      <h5 style={{ alignSelf: "center", color: "white", margin: "0px 5px" }}>
        {label}
      </h5>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const boxRef = useRef(null);
  const boxRef2 = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [gameNames, setGameNames] = useState([
    { name: "Cricket", link: "/live/4", enabled: true },
    { name: "Soccer", link: "/live/1", enabled: true },
    { name: "Tennis", link: "/live/2", enabled: true },
    { name: "Basketball", link: "/basketball", enabled: false },
    { name: "Hockey", link: "/hockey", enabled: false },
    { name: "Baseball", link: "/baseball", enabled: false },
    { name: "Golf", link: "/golf", enabled: false },
    { name: "Volleyball", link: "/volleyball", enabled: false },
    { name: "Rugby", link: "/rugby", enabled: false },
    { name: "Badminton", link: "/badminton", enabled: false },
  ]);

  const onClickInplayOption = (path) => {
    navigate(path);
  };

  const topGamesCard = () => {
    return topGames.map((game, index) => (
      <TopGamesPlayOption
        key={index}
        game={game}
        onClick={onClickInplayOption}
      />
    ));
  };

  const gameShowsCard = () => {
    return gameShows.map((game, index) => (
      <GameShowsPlayOption
        key={index}
        game={game}
        onClick={onClickInplayOption}
      />
    ));
  };

  const royalGamesCard = () => {
    return royalGames.map((game, index) => (
      <GameShowsPlayOption
        key={index}
        game={game}
        onClick={onClickInplayOption}
      />
    ));
  };

  const bonusCards = () => {
    return bonus.map((bonus, index) => (
      <BonusPlayOption key={index} bonus={bonus} onClick={() => {}} />
    ));
  };

  const gameCards = () => {
    return gameNames.map((game, index) => (
      <GameOption
        key={index}
        matchCount={Math.floor(Math.random() * 100)}
        game={game}
        onClick={onClickInplayOption}
      />
    ));
  };

  const midIndex = Math.ceil(topGames.length / 2);
  const firstHalf = topGamesCard().slice(0, midIndex);
  const secondHalf = topGamesCard().slice(midIndex);

  const midIndex1 = Math.ceil(topGames.length / 2);
  const firstHalf1 = gameShowsCard().slice(0, midIndex1);
  const secondHalf1 = gameShowsCard().slice(midIndex1);

  const midIndex2 = Math.ceil(topGames.length / 2);
  const firstHalf2 = royalGamesCard().slice(0, midIndex2);
  const secondHalf2 = royalGamesCard().slice(midIndex2);

  const [cricketMatches, setCricketMatches] = useState([]);
  const [soccerMatches, setSoccerMatches] = useState([]);
  const [tennisMatches, setTennisMatches] = useState([]);

  const [getGames, setGetGames] = useState([]);
  const blockedSports = JSON.parse(localStorage.getItem("blocked_sports"));
  const [isBlockedSports, setIsBlockedSports] = useState([]);

  useEffect(() => {
    const getCricketLiveMatches = async () => {
      try {
        let res = await axios.get("/matches/getLiveMatches/4");
        if (res.data.status) {
          setCricketMatches(res.data.dataobj);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const getSoccerLiveMatches = async () => {
      try {
        let res = await axios.get("/matches/getLiveMatches/1");
        if (res.data.status) {
          setSoccerMatches(res.data.dataobj);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const getTennisLiveMatches = async () => {
      try {
        let res = await axios.get("/matches/getLiveMatches/2");
        if (res.data.status) {
          setTennisMatches(res.data.dataobj);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getCricketLiveMatches();
    getSoccerLiveMatches();
    getTennisLiveMatches();
  }, []);

  useEffect(() => {
    const box = boxRef.current;

    const handleScroll = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        box.scrollLeft += event.deltaY;
      }
    };

    if (box) {
      box.addEventListener("wheel", handleScroll);
    }
    return () => {
      if (box) {
        box.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const box = boxRef2.current;

    const handleScroll = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        box.scrollLeft += event.deltaY;
      }
    };

    if (box) {
      box.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (box) {
        box.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const getGamesId = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setGetGames(result?.data?.dataobj);
      } catch (err) {
        console.log(err);
      }
    };
    getGamesId();
  }, []);

  useEffect(() => {
    if (getGames.length && blockedSports?.length) {
      const updatedGameNames = gameNames.map((game) => {
        const matchingGame = getGames.find(
          (g) =>
            g.gName === game.name &&
            blockedSports.some((b) => b.sportId === g._id)
        );

        if (matchingGame) {
          const matchingBlock = blockedSports.find(
            (b) => b.sportId === matchingGame._id
          );

          return {
            ...game,
            enabled: !matchingBlock.isBlocked,
          };
        }

        return game;
      });
      setIsBlockedSports(
        updatedGameNames.reduce((acc, game) => {
          acc[game.name] = game.enabled;
          return acc;
        }, {})
      );
      setGameNames(updatedGameNames);
    }
  }, [getGames]);

  return (
    <Box
      sx={{
        width: "auto",
        height: "auto",
        paddingBottom: "100px",
        paddingTop: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box sx={{ width: isMobile ? "100%" : "800px", margin: "0 auto" }}>
        <Box sx={{ paddingLeft: isMobile ? "" : "22px" }}>
          <Slider {...settings}>
            {bonusCards()
              .concat(bonusCards())
              .map((card, index) => (
                <Box
                  key={index}
                  className="scroll-container-card"
                  sx={{
                    width: "100%",
                    padding: "0px 10px 0px 0px",
                    borderRadius: "10px",
                  }}
                >
                  {card}
                </Box>
              ))}
          </Slider>
        </Box>

        <Box
          sx={{
            width: isMobile ? "100%" : "97%",
            maxWidth: "820px",
            margin: isMobile ? "0 auto" : "0px 0px 0px 22px",
            overflow: "hidden",
            backgroundColor: "#18AEC5",
            padding: "6px 0",
          }}
        >
          <Typography
            component="div"
            sx={{
              display: "inline-block",
              whiteSpace: "nowrap",
              color: "white",
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
              paddingLeft: "20px",
              animation: "scroll-left 15s linear infinite",
            }}
          >
            Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
            Ipsum
          </Typography>
        </Box>

        <Box sx={{ padding: isMobile ? "4px 0px 0px 0xpx" : "4px" }}>
          <Box
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              pl: isMobile ? "" : "10px",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                {gameCards()?.slice(0, 10)}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginBottom: "0px",
            padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
          }}
        >
          <Typography
            sx={{
              color: "black",
              display: "inline-block",
              fontWeight: "bold",
              marginBottom: "1px",
              pl: isMobile ? "" : "12px",
              pt: "10px",
            }}
          >
            Top Games
          </Typography>
          <Box
            ref={boxRef}
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              scrollbarWidth: isMobile ? "none" : "none",
              "&::-webkit-scrollbar": {
                display: isMobile ? "none" : "none",
              },
              ml: isMobile ? "" : "10px",
            }}
          >
            <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                {firstHalf}
              </Box>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                {secondHalf}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginBottom: "0px",
            padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
          }}
        >
          <Typography
            sx={{
              color: "black",
              display: "inline-block",
              pt: "10px",
              marginBottom: "0px",
              pl: isMobile ? "" : "12px",
              fontWeight: "bold",
            }}
          >
            Game Shows
          </Typography>
          <Box
            ref={boxRef2}
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              scrollbarWidth: isMobile ? "none" : "none",
              ml: isMobile ? "" : "12px",
              "&::-webkit-scrollbar": {
                display: isMobile ? "none" : "none",
              },
            }}
          >
            <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                {firstHalf1}
              </Box>
              <Box
                sx={{ display: "flex", flexWrap: "nowrap", marginTop: "8px" }}
              >
                {secondHalf1}
              </Box>
            </Box>
          </Box>
        </Box>

        {isBlockedSports?.Cricket && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "-12px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Cricket
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px",
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/4")}
                >
                  See All
                </Button>
              </Box>

              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "280px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {cricketMatches.slice(0, 2).map((match, index) => (
                <MatchCard key={index} match={match} sport_id="4" />
              ))}
            </Box>
          </Box>
        )}

        {isBlockedSports?.Soccer && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "-12px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Soccer
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px",
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/1")}
                >
                  See All
                </Button>
              </Box>

              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "280px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {soccerMatches.slice(0, 2).map((match, index) => (
                <MatchCard key={index} match={match} sport_id="1" />
              ))}
            </Box>
          </Box>
        )}

        {isBlockedSports?.Tennis && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "-12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Tennis
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px",
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/2")}
                >
                  See All
                </Button>
              </Box>

              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    pt: "10px",
                    justifyContent: "space-between",
                    width: "280px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {tennisMatches.slice(0, 2).map((match, index) => (
                <MatchCard key={index} match={match} sport_id="2" />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <MainFooter />
    </Box>
  );
};

export default Home;
