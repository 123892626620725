import React, { useState, useEffect } from "react";
import logo from "../../Assets/Logo2.png";
import { FaUser, FaKey } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import axios from "../../authAxios";
import "./Login.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Popup from "../../Componant/Popup/Popup";
import sha256 from "sha256";
import cricketBgImg from "../../Assets/Cricket.jpg";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [userCaptcha, setUserCaptcha] = useState("");
  const [popUpShow, setPopUpShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [title, setTitle] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    localStorage.getItem("isUserLoggedIn")
  );
  const navigate = useNavigate();

  useEffect(() => {
    generateCaptcha();
  }, []);

  const validatePassword = (password) => {
    const errors = [];

    const isValidLength = password.length >= 8;

    if (!isValidLength) errors.push("at least 8 characters long.");

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      setPasswordErrors(passwordErrors);
      return;
    } else {
      setPasswordErrors([]);
    }

    if (captcha !== userCaptcha) {
      showErrorPopup("Wrong Captcha");
      return;
    }
    try {
      const response = await axios.post("/auth/login", {
        usernameOrPhone: username,
        password: sha256(password),
      });
      localStorage.setItem("authToken", response.data.accessToken);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.accessToken;
      storeUserCredentials(response.data);
      navigate("/");
    } catch (error) {
      handleLoginError();
    }
  };

  const showErrorPopup = (message) => {
    setMsg(message);
    setTitle("Error");
    setPopUpShow(true);
  };

  const handleLoginError = () => {
    setPassword("");
    setUsername("");
    setUserCaptcha("");
    generateCaptcha();
    showErrorPopup("Login Fail");
  };

  const storeUserCredentials = (userData) => {
    const blockedSportsString = JSON.stringify(userData.blocked_sports);
    localStorage.setItem("userName", userData.username);
    localStorage.setItem("user_id", userData._id);
    localStorage.setItem("user_type", userData.user_type);
    localStorage.setItem("email", userData.email);
    localStorage.setItem("isUserLoggedIn", true);
    localStorage.setItem("player_bal", userData.credits);
    localStorage.setItem("player_exp", userData.exposure);
    localStorage.setItem("authToken", userData.accessToken);
    localStorage.setItem("blocked_sports", blockedSportsString);
    Cookies.set("jwtToken", userData.accessToken, {
      expires: 7,
      secure: true,
      sameSite: "None",
    });
  };

  const generateCaptcha = () => {
    const characters = "0123456789";
    let captcha = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(randomIndex);
    }
    setCaptcha(captcha);
  };

  const onClick = () => {
    setPopUpShow(false);
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn, navigate]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${cricketBgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div>
        <div style={{ marginBottom: "35px" }}>
          <img
            style={{ display: "block", margin: "auto", width: "150px" }}
            src={logo}
            alt=""
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div
            className="fromDiv"
            style={{
              backgroundColor: "whitesmoke",
              padding: "10px 20px",
              borderRadius: "15px",
              maxWidth: "290px",
            }}
          >
            <label htmlFor="username">UserName</label>
            <br />
            <div className="inputBox">
              <span style={{ marginRight: "10px" }}>{<FaUser />}</span>{" "}
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <label htmlFor="password">Password</label>
            <br />
            <div className="inputBox">
              <span style={{ marginRight: "10px" }}>{<FaKey />}</span>{" "}
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {passwordErrors.length > 0 && (
              <div style={{ color: "red", marginTop: "10px" }}>
                <p style={{ marginBottom: "0px !important" }}>
                  Password must contain :
                </p>
                {passwordErrors.length > 0 && (
                  <ul
                    style={{
                      color: "red",
                      margin: "0",
                      padding: "0",
                      listStyleType: "disc",
                      paddingLeft: "20px",
                    }}
                  >
                    {passwordErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <label htmlFor="captcha">Enter Captcha</label>
            <p
              style={{
                color: "green",
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              {captcha}
            </p>
            <div className="inputBox">
              <input
                style={{ textAlign: "center" }}
                type="text"
                id="captcha"
                value={userCaptcha}
                onChange={(e) => setUserCaptcha(e.target.value)}
                required
              />
            </div>
            <button type="submit">LOGIN</button>
            <p
              style={{
                color: "#414141",
                textAlign: "right",
                fontSize: "13px",
                marginTop: "5px",
              }}
            >
              Forgot password?
            </p>
          </div>
        </form>
      </div>
      {popUpShow && <Popup onClick={onClick} msg={msg} title={title} />}
    </div>
  );
};

export default Login;
