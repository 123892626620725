import React, { useState } from "react";

export default function SingleMatchLedger() {
  const [bets, setbets] = useState(["a", "b", "c"]);
  return bets.map((item) => {
    return <Bet />;
  });
}
const Bet = () => {
  return (
    <div>
      <div style={{ textAlign: "center", margin: "15px 0px" }}>
        <div
          style={{
            backgroundColor: "black",
            padding: "10px 0px",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <h6 style={{ margin: "0px" }}>Match Toss Bets</h6>
        </div>
        <div
          style={{
            backgroundColor: "whitesmoke",
            padding: "10px 0px",
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
          }}
        >
          <h6 style={{ margin: "0px", color: "green" }}>You Won O/-Coins</h6>
        </div>
        <div></div>
      </div>
    </div>
  );
};
