import React from "react";
import {
  useTheme,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./Footer.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="footer"
      sx={{
        position: "fixed",
        bottom: 0,
        left: "50.05%",
        transform: "translateX(-50%)",
        width: isMobile ? "100%" : "41%",
        height: "60px",
        backgroundColor: "#18AEC5",
        padding: "5px 0",
        display: "flex",
        borderRadius: isMobile ? "10px 10px 0px 0px" : "10px",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <FooterButton
          icon="/image/footer/home.svg"
          label="Home"
          handleClick={() => {
            navigate("/");
          }}
        />
        <Box
          className="special-button"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderRadius: "8px",
          }}
        >
          <FooterButton
            icon="/image/footer/cricket.webp"
            special
            handleClick={() => {
              navigate("/inplay");
            }}
          />
          <Typography
            variant="caption"
            sx={{ textAlign: "center", mt: "-18px", color: "white" }}
          >
            Cricket Fight
          </Typography>
        </Box>

        <FooterButton
          icon="/image/footer/mymarket.svg"
          label="My Market"
          handleClick={() => {
            navigate("/my-market");
          }}
        />
      </Box>
    </Box>
  );
};

const FooterButton = ({ icon, label, special, handleClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: special ? "relative" : "static",
        top: special ? "-25px" : "0",
      }}
      onClick={() => handleClick()}
    >
      <IconButton
        sx={{
          backgroundColor: special ? "white" : "transparent",
          borderRadius: "50%",
          width: special ? 50 : 30,
          height: special ? 50 : 30,
          mb: 1,
        }}
      >
        <img
          src={icon}
          alt={label}
          style={{
            width: special ? 30 : 20,
            height: special ? 30 : 20,
            objectFit: "contain",
            filter: "invert(100%)",
          }}
        />
      </IconButton>
      {label && (
        <Typography
          variant="caption"
          sx={{ color: "white", fontSize: "0.65rem", textAlign: "center" }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
