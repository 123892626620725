import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { BiSolidCricketBall } from "react-icons/bi";
import { GiSoccerBall } from "react-icons/gi";
import { PiTennisBallFill } from "react-icons/pi";
import { MdCasino } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import GameListItem from "../../Componant/Gamelistitem.js";
import axios from "../../authAxios.js";
import { FadeLoader } from "react-spinners";
import "./UpcommingMatches.css";

export default function UpcommingMatches() {
  const navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [sports_id, setSports_id] = useState(4);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const getUpcomingMatches = async (sports_id) => {
      setloading(true);
      setMatches([]);
      try {
        let res = await axios.get("/matches/getUpcommingMatches/" + sports_id);
        if (res.data.status) {
          setMatches(res.data.dataobj);
        }
        setloading(false);
      } catch (err) {
        console.log(err);
        setloading(false);
      }
    };

    getUpcomingMatches(sports_id);
  }, []);
  function convertUTCToIST(utcDate) {
    var date = new Date(utcDate);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    var istDateString = date.toLocaleString("en-US", options);

    return istDateString;
  }
  const InPlayOption = ({
    startColor,
    endColor,
    label,
    icon,
    onClick = () => console.log("THIS GOT CLICKED", label),
  }) => {
    return (
      <div
        style={{
          background: `linear-gradient(to left, ${startColor} , ${endColor})`,
          padding: "0px 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
        }}
        onClick={onClick}
      >
        <span style={{ padding: "8px" }}>{icon}</span>
        <span style={{ padding: "8px", whiteSpace: "nowrap" }}>{label}</span>
      </div>
    );
  };

  return (
    <div>
      <div
        className="optionHolder"
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "12px",
        }}
      >
        <InPlayOption
          label={"Cricket"}
          icon={<BiSolidCricketBall />}
          startColor="#8aff8a"
          endColor={"#00cc00"}
          onClick={() => setSports_id(4)}
        />
        <InPlayOption
          label={"Soccer"}
          icon={<GiSoccerBall />}
          startColor="#a994ff"
          endColor={"#735cff"}
          onClick={() => setSports_id(1)}
        />
        <InPlayOption
          label={"Tennis"}
          icon={<PiTennisBallFill />}
          startColor="#800000"
          endColor="#ff4d4d"
          onClick={() => setSports_id(2)}
        />
        <InPlayOption
          label={"Live Cassino"}
          icon={<MdCasino />}
          startColor="#ffcc66"
          endColor="#ff9900"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px 0",
        }}
      >
        <FadeLoader loading={loading} color="#735cff" />
        {matches?.length === 0 ? (
          <Alert key="danger" variant="danger">
            Currently no match available!
          </Alert>
        ) : (
          <div style={{ width: "100%" }}>
            {matches.map((item) => (
              <GameListItem
                key={item.match_id}
                dateTime={convertUTCToIST(
                  (item?.matchObj?.openDate).toString()
                )}
                teams={item?.matchObj?.name}
                mBets={0}
                sBets={0}
                onClick={() => navigate("/match/" + item?.matchObj?.id)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
