import React, { useState, useEffect } from "react";
import axios from "../../authAxios";
import { useParams } from "react-router-dom";
import Table from "../../Componant/Table/Table";

export default function Ledger() {
  const params = useParams();
  const [ledgerData, setLedgerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/users/getUserLedger", {
          params: {
            user_id: localStorage.getItem("user_id"),
            match_result: "declared",
          },
        });

        if (response.data.status === 1) {
          setLedgerData(response.data.dataobj);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [params.user_id]);

  const columns = [
    { name: "Match Title", selector: (row) => row.match_title },
    { name: "Odds Win", selector: (row) => row.odds_winnings },
    { name: "Bookmaker Win", selector: (row) => row.book_maker_winnings },
    {
      name: "Total Win",
      selector: (row) => row.odds_winnings + row.book_maker_winnings,
    },
  ];

  return (
    <div>
      <Table data={ledgerData} columns={columns} title="Ledger of all games" />
    </div>
  );
}
