import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  InputBase,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountCircle,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo2.png";
import "./Header.scss";
import axios from "../../authAxios";
import UserProfileDialog from "../../Componant/UserProfileDialog";

const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  } else {
    return num;
  }
};
const Header = ({ onMenuClick, handleMenuClick }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [balance, setBalance] = useState();
  const [exposure, setExposure] = useState();
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  useEffect(() => {
    const userLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
    setIsUserLoggedIn(userLoggedIn);
  }, []);

  const handleLogoutIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsUserLoggedIn(false);
    setAnchorEl(null);
    navigate("/login");
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const gotoHome = () => {
    navigate("/");
  };

  const handleProfileClick = () => {
    setProfileDialogOpen(true);
    handleCloseMenu();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/users/userAccDetails", {
          params: { _id: localStorage.getItem("user_id") },
        });
        setBalance(response.data.balance);
        setExposure(response.data.exposure);
      } catch (err) {
        console.log(err);
      }
    };

    if (isUserLoggedIn) {
      fetchData();
      const intervalId = setInterval(fetchData, 10000);

      return () => clearInterval(intervalId);
    }
  }, [isUserLoggedIn]);

  const formatNumber = (num) => {
    const absNum = Math.abs(num);

    if (absNum >= 1e12) return `${Math.floor(num / 1e12)}T`;
    if (absNum >= 1e9) return `${Math.floor(num / 1e9)}B`;
    if (absNum >= 1e6) return `${Math.floor(num / 1e6)}M`;

    return num?.toLocaleString();
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#18AEC5", paddingX: isMobile ? 2 : 50 }}
    >
      <Toolbar
        className="header"
        sx={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobile ? "100%" : "840px",
          backgroundColor: "#18AEC5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onMenuClick}
              sx={{
                ml: isMobile ? -2 : 2,
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              className={`logo ${isMobile ? "mobile-logo" : ""}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ height: isMobile ? "20px" : "25px" }}
                onClick={gotoHome}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              ml: isMobile ? 0 : 2,
            }}
          >
            {isUserLoggedIn && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: isMobile ? 0.5 : 2,
                  color: "white",
                  fontSize: isMobile ? "0.8rem" : "1rem",
                }}
              >
                <Box>Balance: {formatNumber(balance)}</Box>
                <Box>Exposure: {formatNumber(exposure)}</Box>
              </Box>
            )}

            {isUserLoggedIn ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  ml: isMobile ? 0 : 2,
                }}
              >
                <IconButton
                  onClick={handleLogoutIconClick}
                  sx={{ color: "white" }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleProfileClick}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                onClick={() => navigate("/login")}
                sx={{
                  color: "black",
                  bgcolor: "white",
                  "&:hover": {
                    bgcolor: "#30673c",
                    color: "white",
                  },
                  flexGrow: 0,
                  ml: isMobile ? 0 : 2,
                }}
                variant="contained"
              >
                Log in
              </Button>
            )}
          </Box>
        </Box>
      </Toolbar>
      <UserProfileDialog
        open={profileDialogOpen}
        onClose={() => setProfileDialogOpen(false)}
        balance={balance}
        exposure={exposure}
      />
    </AppBar>
  );
};

export default Header;
