import React, { useEffect, useRef, useState } from "react";
import "./MyMarket.scss";
import "./animations.css"; // Import the CSS file
import { Box, Card, Typography, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "../../authAxios";
import MainFooter from "../MainFooter/MainFooter";
import UndeclaredMatchCard from "../Matches/undeclaredMatchCard";

const bonus = [
  { name: "BONUS1", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS2", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS3", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS4", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  { name: "BONUS5", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
];

const BonusPlayOption = ({ bonus, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      onClick={() => onClick(bonus.link)}
      sx={{
        width: isMobile ? "97%" : "100%",
        height: isMobile ? "160px" : "110px",
        marginLeft: "10px",
        backgroundImage: `url(${bonus.bgImg})`,
        backgroundColor: "gray",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "0px solid white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px",
        cursor: "pointer",
      }}
    >
      <Box sx={{ flexGrow: 1 }}></Box>{" "}
    </Card>
  );
};

const MyMarket = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [cricketMatches, setCricketMatches] = useState([]);
  const [tennisMatches, setTennisMatches] = useState([]);
  const [soccerMatches, setSoccerMatches] = useState([]);

  const [getGames, setGetGames] = React.useState([]);
  const [isBlockedSports, setIsBlockedSports] = React.useState({});
  const blockedSports = JSON.parse(localStorage.getItem("blocked_sports"));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const bonusCards = () => {
    return bonus.map((bonus, index) => (
      <BonusPlayOption key={index} bonus={bonus} onClick={() => {}} />
    ));
  };

  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const getUndeclaredMatches = async () => {
      try {
        const userId = window.localStorage.getItem("user_id");
        let res = await axios.get(`/users/get-my-bets?user_id=${userId}`);
        if (res.data.status) {
          const cricketMatches = res.data.undeclaredMatches.filter(
            (match) => match.type === "cricket"
          );
          const tennisMatches = res.data.undeclaredMatches.filter(
            (match) => match.type === "tennis"
          );
          const soccerMatches = res.data.undeclaredMatches.filter(
            (match) => match.type === "soccer"
          );
          setCricketMatches(cricketMatches);
          setTennisMatches(tennisMatches);
          setSoccerMatches(soccerMatches);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getUndeclaredMatches();
  }, []);

  useEffect(() => {
    const getGamesId = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setGetGames(result?.data?.dataobj);
      } catch (err) {
        console.log(err);
      }
    };
    getGamesId();
  }, []);

  useEffect(() => {
    if (getGames.length > 0 && blockedSports) {
      const blockedSportsObj = getGames.reduce((acc, game) => {
        const blockedStatus = blockedSports.find(
          (sport) => sport.sportId === game._id
        );
        acc[game.gName] = blockedStatus ? blockedStatus.isBlocked : false;
        return acc;
      }, {});
      setIsBlockedSports(blockedSportsObj);
    }
  }, [getGames]);

  return (
    <Box
      sx={{
        width: "auto",
        height: "auto",
        paddingBottom: "100px",
        paddingTop: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box sx={{ width: isMobile ? "100%" : "800px", margin: "0 auto" }}>
        <Box sx={{ paddingLeft: isMobile ? "" : "22px" }}>
          <Slider {...settings}>
            {bonusCards()
              .concat(bonusCards())
              .map((card, index) => (
                <Box
                  key={index}
                  className="scroll-container-card"
                  sx={{
                    width: "100%",
                    padding: "0px 10px 0px 0px",
                    borderRadius: "10px",
                  }}
                >
                  {card}
                </Box>
              ))}
          </Slider>
        </Box>

        <Box
          sx={{
            width: isMobile ? "100%" : "97%",
            maxWidth: "820px",
            margin: isMobile ? "0 auto" : "0px 0px 0px 22px",
            overflow: "hidden",
            backgroundColor: "#18AEC5",
            padding: "6px 0",
          }}
        >
          <Typography
            component="div"
            sx={{
              display: "inline-block",
              whiteSpace: "nowrap",
              color: "white",
              fontSize: { xs: "10px", sm: "12px", md: "14px" }, // Responsive font size
              paddingLeft: "20px", // Padding from the left
              animation: "scroll-left 15s linear infinite",
            }}
          >
            Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
            Ipsum
          </Typography>
        </Box>

        <Box
          sx={{
            marginBottom: "0px",
            padding: isMobile ? "10px 0px 0px 0xpx" : "10px 0px 10px 15px",
            backgroundColor: "#f5f5f5",
            minHeight: "80vh",
          }}
        >
          {cricketMatches.length !== 0 && !isBlockedSports.Cricket && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Cricket
                </Typography>
                {!isMobile && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      pt: "10px",
                      pr: isMobile ? "" : "12px",
                    }}
                  >
                    Total Bets
                  </Typography>
                )}
              </Box>
              <Box sx={{ paddingLeft: "2px", width: "100%" }}>
                {cricketMatches.slice(0, 2).map((match, index) => (
                  <UndeclaredMatchCard key={index} match={match} sport_id="4" />
                ))}
              </Box>
            </>
          )}
          {tennisMatches.length !== 0 && !isBlockedSports.Tennis && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Tennis
                </Typography>
                {!isMobile && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      pt: "10px",
                      pr: isMobile ? "" : "12px",
                    }}
                  >
                    Total Bets
                  </Typography>
                )}
              </Box>
              <Box sx={{ paddingLeft: "2px", width: "100%" }}>
                {tennisMatches.slice(0, 2).map((match, index) => (
                  <UndeclaredMatchCard key={index} match={match} sport_id="4" />
                ))}
              </Box>
            </>
          )}
          {soccerMatches.length !== 0 && !isBlockedSports.Soccer && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Soccer
                </Typography>
                {!isMobile && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      pt: "10px",
                      pr: isMobile ? "" : "12px",
                    }}
                  >
                    Total Bets
                  </Typography>
                )}
              </Box>
              <Box sx={{ paddingLeft: "2px", width: "100%" }}>
                {soccerMatches.slice(0, 2).map((match, index) => (
                  <UndeclaredMatchCard key={index} match={match} sport_id="4" />
                ))}
              </Box>
            </>
          )}
          {cricketMatches.length === 0 &&
            tennisMatches.length === 0 &&
            soccerMatches.length === 0 && (
              <Box
                sx={{
                  paddingTop: "50px",
                  paddingLeft: "2px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Ongoing Bets
              </Box>
            )}
        </Box>
      </Box>
      <MainFooter />
    </Box>
  );
};

export default MyMarket;
