import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";

export const BetPrediction = ({ bet, open, onClose }) => {
  if (!bet || !bet.bets || (!bet.bets["Yes"] && !bet.bets["No"])) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Exposure</DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h6" color="textSecondary">
            No bets found
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const { bets } = bet;

  const noBet = bets["No"]?.bets[0] || null;
  const yesBet = bets["Yes"]?.bets[0] || null;

  const noBetAmount = bets["No"]?.bet_amount || 0;
  const yesBetAmount = bets["Yes"]?.bet_amount || 0;

  const layPrice = noBet?.fancy_Detail?.layPrice1 || null;
  const backPrice = yesBet?.fancy_Detail?.backPrice1 || null;

  // Handle case where neither price is available
  if (!layPrice && !backPrice) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Exposure</DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h6" color="textSecondary">
            No bets found
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Build outcomes dynamically
  const outcomes = [];
  if (layPrice) {
    outcomes.push(layPrice - 1, layPrice, layPrice + 1);
  }
  if (backPrice) {
    outcomes.push(backPrice - 1, backPrice, backPrice + 1);
  }

  const uniqueOutcomes = [...new Set(outcomes)].sort((a, b) => a - b);
  // Calculate amounts
  const amounts = calculateAmounts(uniqueOutcomes, noBetAmount, yesBetAmount);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Exposure</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Outcome</TableCell>
              <TableCell align="center">Result</TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueOutcomes.map((outcome, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: amounts[index] > 0 ? "#add8e6" : "#f8d7da",
                }}
              >
                <TableCell align="center">{outcome}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: amounts[index] > 0 ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {amounts[index] > 0 ? "Win" : "Lose"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: amounts[index] > 0 ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {amounts[index]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Helper function for amount calculation
const calculateAmounts = (uniqueOutcomes, noBetAmount, yesBetAmount) => {
  const amounts = [];

  // First row: "No" bet amount - "Yes" bet amount
  amounts.push(noBetAmount - yesBetAmount);

  // Second row(s): -("No" bet amount + "Yes" bet amount)
  const negativeSum = -(Math.abs(noBetAmount) + Math.abs(yesBetAmount));
  const secondRowRepeats =
    uniqueOutcomes?.length === 6
      ? 3
      : uniqueOutcomes?.length === 5
      ? 2
      : uniqueOutcomes?.length === 4
      ? 1
      : 0;

  for (let i = 0; i < secondRowRepeats; i++) {
    amounts.push(negativeSum);
  }

  // Remaining rows: "Yes" bet amount - "No" bet amount
  for (let i = secondRowRepeats + 1; i < uniqueOutcomes.length; i++) {
    amounts.push(yesBetAmount - noBetAmount);
  }

  return amounts;
};
