import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../authAxios";

export default function UndeclaredMatchCard({ match, sport_id }) {
  const navigate = useNavigate();
  const startTime = match?.start_time || "N/A";
  const id = match?.match_id || "N/A";
  const team1 = match?.team1 || "Team 1";
  const team2 = match?.team2 || "Team 2";
  // const inplay = match?.odds[0]?.inplay;
  // const [odds, setOdds] = useState([]); // Example odds
  const betCount = match?.bet_count || 0;
  const [market_id, setMarketId] = useState();
  const [market_odds, setMarketOdds] = useState();
  const boxRef = useRef(null);
  const formattedDateTime =
    startTime !== "N/A"
      ? new Date(startTime).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
      : "N/A";

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const box = boxRef.current;

    const handleScroll = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        box.scrollLeft += event.deltaY;
      }
    };

    if (box) {
      box.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (box) {
        box.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  return (
    <Card
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        margin: "10px 0",
        background: "white",
      }}
      onClick={() => navigate(`/match/${id}/sport/${sport_id}`)}
    >
      <CardContent
        sx={{
          // border: "1px solid #e0e0e0",
          // borderRadius: "5px",
          padding: isMobile ? "16px 0px 0px 0px" : "16px",
          // background: "white",
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Match Information */}
          <Grid item xs={12} md={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="700"
              >
                {formattedDateTime}
              </Typography>
            </div>
            <Typography variant="subtitle1" fontWeight="700">
              {team1} VS {team2}
            </Typography>
          </Grid>

          {isMobile ? (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "left" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  pt: "10px",
                  pr: isMobile ? "30px" : "12px",
                }}
              >
                Total Bets :
              </Typography>

              <Typography
                sx={{
                  fontWeight: "bold",
                  pt: "10px",
                  pr: isMobile ? "" : "12px",
                }}
              >
                {betCount}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                pt: "10px",
                pr: isMobile ? "" : "12px",
              }}
            >
              {betCount}
            </Typography>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
