import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function Table({ data, columns, title, ...rest }) {
  const gridColumns = columns.map((col) => ({
    field: col.selector,
    headerName: col.name,
    width: col.width,
  }));

  const updatedData = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  return (
    <div
      style={{
        borderRadius: "8px",
        margin: "8px 0",
        backgroundColor: "#fff",
      }}
    >
      <DataGrid
        rows={updatedData}
        columns={gridColumns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </div>
  );
}

export default Table;
