import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Logo2.png";
import { KeyboardArrowDown } from "@mui/icons-material";
import { MdSpaceDashboard } from "react-icons/md";
import { MdGames } from "react-icons/md";
import { MdDoneAll } from "react-icons/md";
import { GiProfit } from "react-icons/gi";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { FaAddressBook } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { MdUpcoming } from "react-icons/md";
import Cookies from "js-cookie";
import "./Sidebar.scss";
function Sidebar({ display, handleOpenClose }) {
  const navigate = useNavigate();
  const handleListItemClick = (path) => {
    handleOpenClose();
    if (path === "/logout") {
      localStorage.clear();
      Cookies.remove("jwtToken");
      navigate("/login");
      return;
    }
    if (localStorage.getItem("isUserLoggedIn")) {
      navigate(path);
      return;
    }

    navigate("/login");
  };

  const SidebarOptionList = [
    {
      title: "Home",
      path: "/",
      icon: <MdSpaceDashboard />,
    },
    {
      title: "IN Play",
      icon: <MdGames />,
    },
    {
      title: "Upcoming",
      path: "/upcoming",
      icon: <MdUpcoming />,
    },
    {
      title: "Ledger",
      path: "/ledger",
      icon: <MdDoneAll />,
    },
    {
      title: "Live Casino",
      path: "/livecasino",
      icon: <GiProfit />,
    },
    {
      title: "Rules",
      path: "/rules",
      icon: <FaClipboardList />,
    },
    {
      title: "Statement",
      path: "/statement",
      icon: <FaAddressBook />,
    },
    {
      title: "Change Password",
      path: "/change-password",
      icon: <TbPasswordMobilePhone />,
    },
    {
      title: "Logout",
      path: "/logout",
      icon: <TbPasswordMobilePhone />,
    },
  ];

  const SideOption = (item) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
      <>
        <div
          className="option"
          onClick={(e) => {
            if (item.isExpandable) {
              setIsExpanded((prev) => !prev);
            } else {
              handleListItemClick(item.path);
            }
          }}
        >
          <div className="option_icon">{item.icon}</div>
          <div className="option_text">
            <span className="option_text_span">{item.title}</span>
          </div>
          <div
            style={{
              transition: "all 0.1s ease",
              flex: 1,
              transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            {item.isExpandable && <KeyboardArrowDown />}
          </div>
        </div>

        {isExpanded && item.isExpandable && (
          <div>
            {item.options.map((obj) => (
              <div
                className="option"
                onClick={(e) => handleListItemClick(obj.path)}
              >
                <div className="option_icon">{obj.icon}</div>
                <div className="option_text">
                  <span className="option_text_span">{obj.title}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };
  return (
    <div className="sidebar" style={display && { display: "block" }}>
      <div className="logo_holder">
        <img src={Logo} />
      </div>
      {SidebarOptionList.map(SideOption)}
    </div>
  );
}

export default Sidebar;
