import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Paper,
} from "@mui/material";

const UserProfileDialog = ({ open, onClose, balance, exposure }) => {
  const userName = localStorage.getItem("userName") || {};

  const userInfo = [
    { label: "Username:", value: userName },
    { label: "Balance:", value: balance },
    { label: "Exposure:", value: exposure },
    { label: "Maximum My Share:", value: userName?.my_match_share },
    { label: "Minimum Company Share:", value: userName?.other_match_share },
    { label: "Match Commission:", value: userName?.match_commission },
    { label: "Session Commission:", value: userName?.session_commission },
  ];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          backgroundColor: "#18AEC5",
          color: "white",
        }}
      >
        Profile
      </DialogTitle>

      <DialogContent>
        <Paper
          sx={{
            paddingTop: "30px",
            borderRadius: "15px",
            boxShadow: 0,
          }}
        >
          <Grid container spacing={3}>
            {userInfo.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Grid container>
                  <Grid item xs={6} sx={{ paddingRight: 2 }}>
                    <Typography
                      variant="h6"
                      color="#64646B"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      color="black"
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.25rem" },
                        fontWeight: "500",
                      }}
                    >
                      {item.value || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserProfileDialog;
