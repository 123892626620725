import React, { useEffect, useState } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import Table from "../../Componant/Table/Table";
import axios from "../../authAxios";
import { Box, TextField, Grid } from "@mui/material";

export default function Statement() {
  const [value, setStartDateEndDate] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  const [data, setData] = useState([]);
  const [apires, setApires] = useState([]);
  const columns = [
    {
      name: "Date",
      selector: "date", // Matching with your data's 'date' field
      width: 150,
      sortable: true,
    },
    {
      name: "Description",
      selector: "description", // Matching with your data's 'description' field
      sortable: true,
      width: 200,
    },
    {
      name: "Cr",
      selector: "credit", // Matching with your data's 'credit' field
      sortable: true,
    },
    {
      name: "Dbt",
      selector: "debit", // Matching with your data's 'debit' field
      sortable: true,
    },
    {
      name: "Balance",
      selector: "balance", // Matching with your data's 'balance' field
      sortable: true,
    },
  ];

  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
            startDate: value.startDate,
            endDate: value.endDate,
          },
        });
        if (res.data.status) {
          setApires(res.data.dataobj);
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    if (value.startDate && value.endDate) {
      getStatements();
    }
  }, [value]);

  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
          },
        });
        if (res.data.status) {
          setApires(res.data.dataobj);
          // setData(getTableReadyData(res.data.dataobj));
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    getStatements();
  }, []);

  return (
    <div
      style={{
        maxWidth: "790px",
        margin: "0 auto",
        minHeight: "90vh",
        paddingBottom: "100px",
      }}
    >
      <Box
        sx={{
          maxWidth: "790px",
          margin: "0 auto",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid container spacing={2} alignItems="center" mt={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.startDate}
              onChange={(e) =>
                setStartDateEndDate({ ...value, startDate: e.target.value })
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.endDate}
              onChange={(e) =>
                setStartDateEndDate({ ...value, endDate: e.target.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>

        <Table columns={columns} data={data} title="Statement" />
      </Box>
    </div>
  );
}
