import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  Card,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import MatchCard from "./matchcard";
import axios from "../../authAxios";
import { useParams, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RulesPopup from "./rulesPopup";

const MatchesList = () => {
  const [matches, setMatches] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const match_id = params.live_id;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bonus = [
    { name: "BONUS1", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS2", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS3", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS4", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS5", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  ];

  useEffect(() => {
    const getLiveMatches = async () => {
      try {
        let res = await axios.get(
          `/matches/getLiveMatches/${match_id}?page=1&limit=100`
        );
        if (res.data.status) {
          setMatches(res.data.dataobj);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getLiveMatches();
  }, [match_id]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const BonusPlayOption = ({ bonus, onClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Card
        onClick={() => onClick(bonus.link)}
        sx={{
          width: isMobile ? "103%" : "100%",
          height: isMobile ? "160px" : "110px",
          margin: "0px",
          backgroundImage: `url(${bonus.bgImg})`,
          backgroundColor: "gray",
          backgroundSize: "cover",
          backgroundPosition: "center",
          border: "0px solid white",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          padding: "0px",
          cursor: "pointer",
        }}
      >
        <Box sx={{ flexGrow: 1 }}></Box>{" "}
      </Card>
    );
  };

  const bonusCards = () => {
    return bonus.map((bonus, index) => (
      <BonusPlayOption
        key={index}
        bonus={bonus}
        onClick={onClickInplayOption}
      />
    ));
  };

  const onClickInplayOption = (path) => {
    // navigate(path);
  };

  const getBackButtonLabel = () => {
    if (match_id == 4) {
      return "Cricket";
    } else if (match_id == 1) {
      return "Soccer";
    } else if (match_id == 2) {
      return "Tennis";
    } else {
      return "Go Back";
    }
  };

  return (
    <Box
      sx={{
        padding: "0px",
        paddingBottom: "100px",
        maxWidth: "770px",
        margin: "0 auto",
      }}
    >
      <Button variant="text" onClick={() => navigate(-1)}>
        <ArrowBackIosIcon /> {getBackButtonLabel()}
      </Button>

      <Box sx={{ padding: "4px", margin: "0 auto" }}>
        <Slider {...settings}>
          {bonusCards()
            .concat(bonusCards())
            .map((card, index) => (
              <Box
                key={index}
                className="scroll-container-card"
                sx={{
                  width: "100%",
                  padding: "0px 10px 0px 0px",
                  borderRadius: "10px",
                }}
              >
                {card}
              </Box>
            ))}
        </Slider>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{ margin: "10px 5px", textAlign: "start", fontWeight: "bold" }}
        >
          All Matches
        </Typography>
        <img
          src="/image/games/rules.png"
          alt="Logo"
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
          onClick={handleOpen}
        />
      </Stack>

      {matches.map((match, index) => (
        <MatchCard key={index} match={match} sport_id={match_id} />
      ))}

      <RulesPopup
        open={open}
        handleClose={handleClose}
        type={getBackButtonLabel()}
      />
    </Box>
  );
};

export default MatchesList;
